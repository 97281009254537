import { NavZhEnUrl } from '../../common/utils'

export const Gartner_SupplyChain2022 = [
    {
        name: "Cisco Systems",
        symbol: "CSCO",
        rank: 1,
        link: NavZhEnUrl('https://zh.wikipedia.org/wiki/%E6%80%9D%E7%A7%91%E7%B3%BB%E7%BB%9F', 'https://en.wikipedia.org/wiki/Cisco'),
    },
    {
        name: "Schneider Electric",
        symbol: "SU",
        rank: 2,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E6%96%BD%E8%80%90%E5%BE%B7%E7%94%B5%E6%B0%94', 'https://en.wikipedia.org/wiki/Schneider_Electric'),
    },
    {
        name: "Colgate-Palmolive ",
        symbol: "CL",
        rank: 3,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E9%AB%98%E9%9C%B2%E6%B4%81-%E6%A3%95%E6%A6%84', 'https://en.wikipedia.org/wiki/Colgate-Palmolive'),
    },
    {
        name: "Johnson & Johnson",
        symbol: "JNJ",
        rank: 4,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%BC%B7%E7%94%9F%E5%85%AC%E5%8F%B8', 'https://en.wikipedia.org/wiki/Johnson_%26_Johnson'),
    },
    {
        name: "PepsiCo",
        symbol: "PEP",
        rank: 5,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E7%99%BE%E4%BA%8B%E5%85%AC%E5%8F%B8', 'https://en.wikipedia.org/wiki/PepsiCo'),
    },
    {
        name: "Pfizer",
        symbol: "PFE",
        rank: 6,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%BC%9D%E7%91%9E', 'https://en.wikipedia.org/wiki/Pfizer'),
    },
    {
        name: "Intel",
        symbol: "INTC",
        rank: 7,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%8B%B1%E7%89%B9%E5%B0%94', 'https://en.wikipedia.org/wiki/Intel'),
    },
    {
        name: "Nestlé",
        symbol: "NSRGY",
        rank: 8,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E9%9B%80%E5%B7%A2', 'https://en.wikipedia.org/wiki/Nestl%C3%A9'),
    },
    {
        name: "Lenovo",
        symbol: "LNVGY",
        rank: 9,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%81%94%E6%83%B3%E9%9B%86%E5%9B%A2', 'https://en.wikipedia.org/wiki/Lenovo'),
    },
    {
        name: "Microsoft",
        symbol: "MSFT",
        rank: 10,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%BE%AE%E8%BD%AF', 'https://en.wikipedia.org/wiki/Microsoft'),
    },
    {
        name: "L'Oréal",
        symbol: "or.pa",
        rank: 11,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%90%8A%E9%9B%85', 'https://en.wikipedia.org/wiki/L%27Or%C3%A9al'),
    },
    {
        name: "Coca-Cola",
        symbol: "KO",
        rank: 12,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%8F%AF%E5%8F%A3%E5%8F%AF%E4%B9%90%E5%85%AC%E5%8F%B8', 'https://en.wikipedia.org/wiki/The_Coca-Cola_Company'),
    },
    {
        name: "Nike",
        symbol: "NKE",
        rank: 13,
        link: NavZhEnUrl('https://zh.wikipedia.org/wiki/%E8%80%90%E5%85%8B', 'https://en.wikipedia.org/wiki/Nike,_Inc.'),
    },
    {
        name: "Walmart",
        symbol: "WMT",
        rank: 14,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E6%B2%83%E5%B0%94%E7%8E%9B', 'https://en.wikipedia.org/wiki/Walmart'),
    },
    {
        name: "HP",
        symbol: "HPQ",
        rank: 15,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E6%83%A0%E6%99%AE', 'https://en.wikipedia.org/wiki/Hewlett-Packard'),
    },
    {
        name: "Diageo",
        symbol: "DEO",
        rank: 16,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%B8%9D%E4%BA%9E%E5%90%89%E6%AD%90', 'https://en.wikipedia.org/wiki/Diageo'),
    },
    {
        name: "Dell",
        symbol: "DELL",
        rank: 17,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E6%88%B4%E7%88%BE', 'https://en.wikipedia.org/wiki/Dell'),
    },
    {
        name: "Inditex",
        symbol: "IDEXF",
        rank: 18,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%8D%B0%E5%9C%B0%E7%B4%A1', 'https://en.wikipedia.org/wiki/Inditex'),
    },
    {
        name: "BMW",
        symbol: "BMW.DE",
        rank: 19,
        link: NavZhEnUrl('https://zh.wikipedia.org/wiki/BMW', 'https://en.wikipedia.org/wiki/BMW'),
    },
    {
        name: "AbbVie",
        symbol: "ABBV",
        rank: 20,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%89%BE%E4%BC%AF%E7%BB%B4', 'https://en.wikipedia.org/wiki/AbbVie'),
    },
    {
        name: "Siemens",
        symbol: "SIEGY",
        rank: 21,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%A5%BF%E9%97%A8%E5%AD%90%E5%85%AC%E5%8F%B8', 'https://en.wikipedia.org/wiki/Siemens'),
    },
    {
        name: "AstraZeneca",
        symbol: "AZN",
        rank: 22,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E9%98%BF%E6%96%AF%E5%88%A9%E5%BA%B7', 'https://en.wikipedia.org/wiki/AstraZeneca'),
    },
    {
        name: "General Mills",
        symbol: "GIS",
        rank: 23,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E9%80%9A%E7%94%A8%E7%A3%A8%E5%9D%8A', 'https://en.wikipedia.org/wiki/General_Mills'),
    },
    {
        name: "British American Tobacco",
        symbol: "BTI",
        rank: 24,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%8B%B1%E7%BE%8E%E7%83%9F%E8%8D%89', 'https://en.wikipedia.org/wiki/British_American_Tobacco'),
    },
    {
        name: "Alibaba",
        symbol: "BABA",
        rank: 25,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%E9%9B%86%E5%9B%A2', 'https://en.wikipedia.org/wiki/Alibaba_Group'),
    },
    {
        name: "GlaxoSmithKline",
        symbol: "GSK",
        rank: 26,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%91%9B%E8%98%AD%E7%B4%A0%E5%8F%B2%E5%85%8B', 'https://en.wikipedia.org/wiki/GSK_plc'),
    },
    {
        name: "Starbucks",
        symbol: "SBUX",
        rank: 27,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E6%98%9F%E5%B7%B4%E5%85%8B', 'https://en.wikipedia.org/wiki/Starbucks'),
    },
    {
        name: "Reckitt Benckiser",
        symbol: "RBGLY",
        rank: 28,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%88%A9%E6%B4%81%E6%97%B6', 'https://en.wikipedia.org/wiki/Reckitt'),
    },
    {
        name: "NVIDIA",
        symbol: "NVDA",
        rank: 29,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%8B%B1%E4%BC%9F%E8%BE%BE', 'https://en.wikipedia.org/wiki/Nvidia'),
    },
    {
        name: "CVS Health",
        symbol: "CVS",
        rank: 30,
        link: NavZhEnUrl('https://en.wikipedia.org/wiki/CVS_Health', 'https://en.wikipedia.org/wiki/CVS_Health'),
    },
    {
        name: "Hewlett Packard Enterprise",
        symbol: "HPE",
        rank: 31,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E6%85%A7%E8%88%87%E7%A7%91%E6%8A%80', 'https://en.wikipedia.org/wiki/Hewlett_Packard_Enterprise'),
    },
    {
        name: "Danone",
        symbol: "BN.PA",
        rank: 32,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%BE%BE%E8%83%BD', 'https://en.wikipedia.org/wiki/Danone'),
    },
    {
        name: "Best Buy",
        symbol: "BBY",
        rank: 33,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E7%99%BE%E6%80%9D%E4%B9%B0', 'https://en.wikipedia.org/wiki/Best_Buy'),
    },
    {
        name: "Philip Morris International",
        symbol: "PM",
        rank: 34,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%8F%B2%E5%88%A9%E6%99%AE%E8%8E%AB%E9%87%8C%E6%96%AF%E5%9B%BD%E9%99%85', 'https://en.wikipedia.org/wiki/Philip_Morris_International'),
    },
    {
        name: "Bayer",
        symbol: "BAYRY",
        rank: 35,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E6%8B%9C%E8%80%B3', 'https://en.wikipedia.org/wiki/Bayer'),
    },
    {
        name: "Abbott",
        symbol: "ABT",
        rank: 36,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E7%BE%8E%E5%9C%8B%E9%9B%85%E5%9F%B9', 'https://en.wikipedia.org/wiki/Abbott_Laboratories'),
    },
    {
        name: "BASF",
        symbol: "BAS.DE",
        rank: 37,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%B7%B4%E6%96%AF%E5%A4%AB', 'https://en.wikipedia.org/wiki/BASF'),
    },
    {
        name: "Novartis",
        symbol: "NVS",
        rank: 38,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%AF%BA%E5%8D%8E', 'https://en.wikipedia.org/wiki/Novartis'),
    },
    {
        name: "3M",
        symbol: "MMM",
        rank: 39,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/3M%E5%85%AC%E5%8F%B8', 'https://en.wikipedia.org/wiki/3M'),
    },
    {
        name: "The Home Depot",
        symbol: "HD",
        rank: 40,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%AE%B6%E5%BE%97%E5%AE%9D', 'https://en.wikipedia.org/wiki/The_Home_Depot'),
    },
    {
        name: "Taiwan Semiconductor",
        symbol: "TSM",
        rank: 41,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%8F%B0%E7%81%A3%E7%A9%8D%E9%AB%94%E9%9B%BB%E8%B7%AF%E8%A3%BD%E9%80%A0', 'https://en.wikipedia.org/wiki/TSMC'),
    },
    {
        name: "Target",
        symbol: "TGT",
        rank: 42,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E7%9B%AE%E6%A8%99%E7%99%BE%E8%B2%A8', 'https://en.wikipedia.org/wiki/Target_Corporation'),
    },
    {
        name: "Novo Nordisk",
        symbol: "NVO",
        rank: 43,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%AF%BA%E5%92%8C%E8%AF%BA%E5%BE%B7', 'https://en.wikipedia.org/wiki/Novo_Nordisk'),
    },
    {
        name: "ASML Holding",
        symbol: "ASML",
        rank: 44,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%89%BE%E5%8F%B8%E6%91%A9%E7%88%BE', 'https://en.wikipedia.org/wiki/ASML_Holding'),
    },
    {
        name: "Anheuser-Busch InBev",
        symbol: "BUD",
        rank: 45,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E5%AE%89%E6%B5%B7%E6%96%AF-%E5%B8%83%E5%B8%8C%E8%8B%B1%E5%8D%9A%E9%9B%86%E5%9C%98', 'https://en.wikipedia.org/wiki/AB_InBev'),
    },
    {
        name: "LG Electronics",
        symbol: "LPL",
        rank: 46,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/LG%E9%9B%BB%E5%AD%90', 'https://en.wikipedia.org/wiki/LG_Electronics'),
    },
    {
        name: "Tesla",
        symbol: "TSLA",
        rank: 47,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E7%89%B9%E6%96%AF%E6%8B%89_(%E5%85%AC%E5%8F%B8)', 'https://en.wikipedia.org/wiki/Tesla,_Inc.'),
    },
    {
        name: "Nokia",
        symbol: "NOK",
        rank: 48,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E8%AF%BA%E5%9F%BA%E4%BA%9A', 'https://en.wikipedia.org/wiki/Nokia'),
    },
    {
        name: "Kimberly-Clark",
        symbol: "KMB",
        rank: 49,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E9%87%91%E7%99%BE%E5%88%A9%E5%85%8B%E6%8B%89%E5%85%8B%E5%85%AC%E5%8F%B8', 'https://en.wikipedia.org/wiki/Kimberly-Clark'),
    },
    {
        name: "Qualcomm",
        symbol: "QCOM",
        rank: 50,
        link: NavZhEnUrl('https://zh.wikipedia.org/zh-tw/%E9%AB%98%E9%80%9A', 'https://en.wikipedia.org/wiki/Qualcomm'),
    },
        
]